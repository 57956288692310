import React, { useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import Cart from "./Cart";
import ModalCountdown from "./ModalCountdown";
import Countdown from "react-countdown";

const Renderer = ({ hours, minutes, seconds }) => {
  return (
    <span>
      <div className="countdown-container">
        <h1>
          {hours}h {minutes}m {seconds}s
        </h1>
      </div>
    </span>
  );
};

const NewNav = ({ props }) => {
  const [toggleMenu, setToggleMenu] = React.useState(false);
  const [clothingMobileSubMenuOpen, setClothingMobileSubMenuOpen] =
    React.useState(false);
  const [miscMobileSubMenuOpen, setMiscMobileSubMenuOpen] =
    React.useState(false);

  useEffect(() => {
    setClothingMobileSubMenuOpen(false);
    setMiscMobileSubMenuOpen(false);
  }, [toggleMenu]);

  return (
    <div className="navbar-parent">
      <header className="navbar-container">
        <div className="navbar-desktop">
          <div className="navbar-logo-container">
            <img
              className="navbar-logo"
              src="/././Assets/partylogo.png"
              alt="navbar logo"
            />
          </div>
          <div className="navbar-title-container">
            <Link to={"/"} className="navbar-title">
              Monke Clothing
            </Link>
          </div>
          <div className="navbar-social-links-container">
            <ul className="navbar-social-links">
              <li>
                <a
                  href="https://twitter.com/monkeclothing "
                  target="_blank"
                  rel="noreferrer"
                  className="social-link"
                >
                  <i className="fab fa-x-twitter fa-1x"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/monkeclothing/"
                  target="_blank"
                  rel="noreferrer"
                  className="social-link"
                >
                  <i className="fab fa-instagram fa-1x"></i>
                </a>
              </li>
            </ul>
          </div>

          <ul className="navbar-buttons">
            <NavLink
              to={"/latest"}
              className={({ isActive }) =>
                isActive
                  ? "navbar-buttons-text link-active"
                  : "navbar-buttons-text"
              }
            >
              Latest Release
            </NavLink>
            <div className="navbar-button-dropdown-clothing">
              <NavLink
                to={"/clothing"}
                className={({ isActive }) =>
                  isActive
                    ? "navbar-buttons-text link-active"
                    : "navbar-buttons-text"
                }
              >
                Clothing
              </NavLink>
              <div className="navbar-button-dropdown-content">
                <NavLink
                  to={"/clothing/all"}
                  className={({ isActive }) =>
                    isActive
                      ? "navbar-buttons-text link-active"
                      : "navbar-buttons-text"
                  }
                >
                  Shop All
                </NavLink>
                <NavLink
                  to={"/clothing/hoodies"}
                  className={({ isActive }) =>
                    isActive
                      ? "navbar-buttons-text link-active"
                      : "navbar-buttons-text"
                  }
                >
                  Hoodies & Sweaters
                </NavLink>
                <NavLink
                  to={"/clothing/shirts"}
                  className={({ isActive }) =>
                    isActive
                      ? "navbar-buttons-text link-active"
                      : "navbar-buttons-text"
                  }
                >
                  T-Shirts
                </NavLink>
                <NavLink
                  to={"/clothing/bottoms"}
                  className={({ isActive }) =>
                    isActive
                      ? "navbar-buttons-text link-active"
                      : "navbar-buttons-text"
                  }
                >
                  Bottoms
                </NavLink>
              </div>
            </div>

            <div className="navbar-button-dropdown-accessories">
              <NavLink to={"/accessories"} className="navbar-buttons-text">
                Accessories
              </NavLink>
              <div className="navbar-button-dropdown-content">
                <NavLink
                  to={"/accessories/all"}
                  className="navbar-buttons-text"
                >
                  Shop All
                </NavLink>
                <NavLink
                  to={"/accessories/plushies"}
                  className="navbar-buttons-text"
                >
                  Plushies
                </NavLink>
                <NavLink
                  to={"/accessories/hats"}
                  className="navbar-buttons-text"
                >
                  Hats
                </NavLink>
                <NavLink
                  to={"/accessories/socks"}
                  className="navbar-buttons-text"
                >
                  Socks
                </NavLink>
                <NavLink
                  to={"/accessories/stickers"}
                  className="navbar-buttons-text"
                >
                  Stickers
                </NavLink>
                <NavLink
                  to={"/accessories/cases"}
                  className="navbar-buttons-text"
                >
                  Cases
                </NavLink>
                <NavLink
                  to={"/accessories/posters"}
                  className="navbar-buttons-text"
                >
                  Posters
                </NavLink>
                <NavLink
                  to={"/accessories/bags"}
                  className="navbar-buttons-text"
                >
                  Bags
                </NavLink>
                <NavLink
                  to={"/accessories/mousepads"}
                  className="navbar-buttons-text"
                >
                  Mousepads
                </NavLink>
                <NavLink
                  to={"/accessories/mugs"}
                  className="navbar-buttons-text"
                >
                  Mugs
                </NavLink>
                <NavLink
                  to={"/accessories/puzzles"}
                  className="navbar-buttons-text"
                >
                  Puzzles
                </NavLink>
                <NavLink
                  to={"/accessories/keychains-pins"}
                  className="navbar-buttons-text"
                >
                  Keychains & Pins
                </NavLink>
                <NavLink
                  to={"/accessories/jewelry"}
                  className="navbar-buttons-text"
                >
                  Jewelry
                </NavLink>
                <NavLink
                  to={"/accessories/magnets"}
                  className="navbar-buttons-text"
                >
                  Magnets
                </NavLink>
                <NavLink
                  to={"/accessories/rugs"}
                  className="navbar-buttons-text"
                >
                  Rugs
                </NavLink>
                <NavLink
                  to={"/accessories/bobbleheads"}
                  className="navbar-buttons-text"
                >
                  Bobble Heads
                </NavLink>
              </div>
            </div>
            <NavLink
              to={"/sale"}
              className={({ isActive }) =>
                isActive
                  ? "navbar-buttons-text link-active"
                  : "navbar-buttons-text"
              }
            >
              Sale
            </NavLink>
            <NavLink
              to={"/gallery"}
              className={({ isActive }) =>
                isActive
                  ? "navbar-buttons-text link-active"
                  : "navbar-buttons-text"
              }
            >
              Gallery
            </NavLink>
            <NavLink
              to={"/support"}
              className={({ isActive }) =>
                isActive
                  ? "navbar-buttons-text link-active"
                  : "navbar-buttons-text"
              }
            >
              Support
            </NavLink>
          </ul>

          <div className="navbar-mobileIcon-container">
            <MenuIcon
              className="navbar-mobile-dropdown"
              onClick={() => setToggleMenu(!toggleMenu)}
            />
          </div>

          {/*--------------------- MOBILE MENU ---------------------*/}

          {toggleMenu && (
            <div className="navbar-mobile-dropdown-container">
              <ul className="navbar-mobile-dropdown-list">
                <NavLink to={"/latest"} className="navbar-buttons-text">
                  <li
                    className="navbar-mobile-dropdown-list-item"
                    onClickCapture={() => setToggleMenu(!toggleMenu)}
                  >
                    Latest Release
                  </li>
                </NavLink>
                <li
                  className="navbar-mobile-dropdown-list-item"
                  onClick={() =>
                    setClothingMobileSubMenuOpen(!clothingMobileSubMenuOpen)
                  }
                >
                  Clothing{" "}
                  <span className="navbar-mobile-dropdown-icon">
                    {clothingMobileSubMenuOpen ? "-" : "+"}
                  </span>
                </li>
                {clothingMobileSubMenuOpen && (
                  <ul className="navbar-mobile-submenu-list">
                    <NavLink
                      to={"/clothing/all"}
                      className="navbar-buttons-text"
                    >
                      <li
                        className="navbar-mobile-submenu-list-item"
                        onClickCapture={() => setToggleMenu(!toggleMenu)}
                      >
                        Shop All
                      </li>
                    </NavLink>
                    <NavLink
                      to={"/clothing/hoodies"}
                      className="navbar-buttons-text"
                    >
                      <li
                        className="navbar-mobile-submenu-list-item"
                        onClickCapture={() => setToggleMenu(!toggleMenu)}
                      >
                        Hoodies & Sweaters
                      </li>
                    </NavLink>
                    <NavLink
                      to={"/clothing/shirts"}
                      className="navbar-buttons-text"
                    >
                      <li
                        className="navbar-mobile-submenu-list-item"
                        onClickCapture={() => setToggleMenu(!toggleMenu)}
                      >
                        T-Shirts
                      </li>
                    </NavLink>
                    <NavLink
                      to={"/clothing/bottoms"}
                      className="navbar-buttons-text"
                    >
                      <li
                        className="navbar-mobile-submenu-list-item"
                        onClickCapture={() => setToggleMenu(!toggleMenu)}
                      >
                        Bottoms
                      </li>
                    </NavLink>
                  </ul>
                )}
                <li
                  className="navbar-mobile-dropdown-list-item"
                  onClick={() =>
                    setMiscMobileSubMenuOpen(!miscMobileSubMenuOpen)
                  }
                >
                  Accessories{" "}
                  <span className="navbar-mobile-dropdown-icon">
                    {miscMobileSubMenuOpen ? "-" : "+"}
                  </span>
                </li>

                {miscMobileSubMenuOpen && (
                  <ul className="navbar-mobile-submenu-list">
                    <NavLink
                      to={"/accessories/all"}
                      className="navbar-buttons-text"
                    >
                      <li
                        className="navbar-mobile-submenu-list-item"
                        onClickCapture={() => setToggleMenu(!toggleMenu)}
                      >
                        Shop All
                      </li>
                    </NavLink>
                    <NavLink
                      to={"/accessories/plushies"}
                      className="navbar-buttons-text"
                    >
                      <li
                        className="navbar-mobile-submenu-list-item"
                        onClickCapture={() => setToggleMenu(!toggleMenu)}
                      >
                        Plushies
                      </li>
                    </NavLink>
                    <NavLink
                      to={"/accessories/hats"}
                      className="navbar-buttons-text"
                    >
                      <li
                        className="navbar-mobile-submenu-list-item"
                        onClickCapture={() => setToggleMenu(!toggleMenu)}
                      >
                        Hats
                      </li>
                    </NavLink>
                    <NavLink
                      to={"/accessories/socks"}
                      className="navbar-buttons-text"
                    >
                      <li
                        className="navbar-mobile-submenu-list-item"
                        onClickCapture={() => setToggleMenu(!toggleMenu)}
                      >
                        Socks
                      </li>
                    </NavLink>
                    <NavLink
                      to={"/accessories/stickers"}
                      className="navbar-buttons-text"
                    >
                      <li
                        className="navbar-mobile-submenu-list-item"
                        onClickCapture={() => setToggleMenu(!toggleMenu)}
                      >
                        Stickers
                      </li>
                    </NavLink>
                    <NavLink
                      to={"/accessories/cases"}
                      className="navbar-buttons-text"
                    >
                      <li
                        className="navbar-mobile-submenu-list-item"
                        onClickCapture={() => setToggleMenu(!toggleMenu)}
                      >
                        Cases
                      </li>
                    </NavLink>
                    <NavLink
                      to={"/accessories/posters"}
                      className="navbar-buttons-text"
                    >
                      <li
                        className="navbar-mobile-submenu-list-item"
                        onClickCapture={() => setToggleMenu(!toggleMenu)}
                      >
                        Posters
                      </li>
                    </NavLink>
                    <NavLink
                      to={"/accessories/bags"}
                      className="navbar-buttons-text"
                    >
                      <li
                        className="navbar-mobile-submenu-list-item"
                        onClickCapture={() => setToggleMenu(!toggleMenu)}
                      >
                        Bags
                      </li>
                    </NavLink>
                    <NavLink
                      to={"/accessories/mousepads"}
                      className="navbar-buttons-text"
                    >
                      <li
                        className="navbar-mobile-submenu-list-item"
                        onClickCapture={() => setToggleMenu(!toggleMenu)}
                      >
                        Mousepads
                      </li>
                    </NavLink>
                    <NavLink
                      to={"/accessories/mugs"}
                      className="navbar-buttons-text"
                    >
                      <li
                        className="navbar-mobile-submenu-list-item"
                        onClickCapture={() => setToggleMenu(!toggleMenu)}
                      >
                        Mugs
                      </li>
                    </NavLink>
                    <NavLink
                      to={"/accessories/puzzles"}
                      className="navbar-buttons-text"
                    >
                      <li
                        className="navbar-mobile-submenu-list-item"
                        onClickCapture={() => setToggleMenu(!toggleMenu)}
                      >
                        Puzzles
                      </li>
                    </NavLink>
                    <NavLink
                      to={"/accessories/keychains-pins"}
                      className="navbar-buttons-text"
                    >
                      <li
                        className="navbar-mobile-submenu-list-item"
                        onClickCapture={() => setToggleMenu(!toggleMenu)}
                      >
                        Keychains & Pins
                      </li>
                    </NavLink>
                    <NavLink
                      to={"/accessories/jewelry"}
                      className="navbar-buttons-text"
                    >
                      <li
                        className="navbar-mobile-submenu-list-item"
                        onClickCapture={() => setToggleMenu(!toggleMenu)}
                      >
                        Jewelry
                      </li>
                    </NavLink>
                    <NavLink
                      to={"/accessories/magnets"}
                      className="navbar-buttons-text"
                    >
                      <li
                        className="navbar-mobile-submenu-list-item"
                        onClickCapture={() => setToggleMenu(!toggleMenu)}
                      >
                        Magnets
                      </li>
                    </NavLink>
                    <NavLink
                      to={"/accessories/rugs"}
                      className="navbar-buttons-text"
                    >
                      <li
                        className="navbar-mobile-submenu-list-item"
                        onClickCapture={() => setToggleMenu(!toggleMenu)}
                      >
                        Rugs
                      </li>
                    </NavLink>
                    <NavLink
                      to={"/accessories/bobbleheads"}
                      className="navbar-buttons-text"
                    >
                      <li
                        className="navbar-mobile-submenu-list-item"
                        onClickCapture={() => setToggleMenu(!toggleMenu)}
                      >
                        Bobble Heads
                      </li>
                    </NavLink>
                  </ul>
                )}
                <NavLink to={"/sale"} className="navbar-buttons-text">
                  <li
                    className="navbar-mobile-dropdown-list-item"
                    onClickCapture={() => setToggleMenu(!toggleMenu)}
                  >
                    Sale
                  </li>
                </NavLink>
                <NavLink to={"/gallery"} className="navbar-buttons-text">
                  <li
                    className="navbar-mobile-dropdown-list-item"
                    onClickCapture={() => setToggleMenu(!toggleMenu)}
                  >
                    Gallery
                  </li>
                </NavLink>
                <NavLink to={"/support"} className="navbar-buttons-text">
                  <li className="navbar-mobile-dropdown-list-item">
                    Support/Returns
                  </li>
                </NavLink>
              </ul>
              <div>{/* Socials */}</div>
            </div>
          )}
        </div>

        {/* Mobile */}
        <div className="navbar-mobile"></div>
        <Cart />
      </header>

    </div>
  );
};

export default NewNav;
